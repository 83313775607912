import { useContext, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { Link } from "react-router-dom";
import { Context } from '../../context/Context';
import image from '../../image/logo.png';
import React from "react";
import { Transition } from "@headlessui/react";
import footerlogo from '../../image/logo-cross-only.png';



import "./topBar.css";


export default function TopBar() {
  const { user, dispatch } = useContext(Context);
  const [isOpen, setIsOpen] = useState(false);

  const handleLogout = () => {
    dispatch({ type: "LOGOUT" });
  };
  return (
    <div>
      <nav className=" bg-white top-0 left-0 z-[999] fixed block  h-20  bg-white shadow-none w-full">
        <div className="w-full px-0 sm:px-4 sm:px-6">
          <div className="flex items-center justify-between w-full h-20">
            <div className='flex items-center justify-between w-full '>
              <div className="flex items-start">
                <img
                  className="object-contain h-auto "
                  src={image}
                />
              </div>
              <div className="hidden md:block">
                <div className="flex items-baseline ml-10 space-x-4 text-black">
                  <Link to={`/`}><a
                    className="px-3 py-2 text-base font-medium rounded-md hover:to-blue-500 "
                  >
                    Home
                  </a>
                  </Link>

                  <Link to={`/teachings`} className="px-3 py-2 text-base font-medium rounded-md hover:to-blue-500">
                    Teachings
                  </Link>
                  <Link
                    to='/events'
                    className="px-3 py-2 text-base font-medium rounded-md hover:to-blue-500"
                  >
                    Events
                  </Link>

                  {/* <Link
                    to="/write"
                    className="px-3 py-2 text-base font-medium rounded-md hover:to-blue-500"
                  >
                    Write
                  </Link> */}

                  <Link
                    to="/contactus"
                    className="px-3 py-2 text-base font-medium rounded-md hover:to-blue-500"
                  >
                    Contact Us
                  </Link>
                  <Link
                    to="/donation"
                    className="px-3 py-2 text-base font-medium rounded-md hover:to-blue-500"
                  >
                    Donate
                  </Link>
                  {/*<div className="topRightab">
                    {user ? (



                      <NavDropdown title={<div className='dropdownprofile'><img className="topImg" src={user.profilePic} alt="" /></div>}>
                        <NavDropdown.Item><Link to="/settings">Profile</Link></NavDropdown.Item>

                        <NavDropdown.Item onClick={handleLogout}>Log Out</NavDropdown.Item>

                      </NavDropdown>

                    ) : (
                      <ul className="mt-2 topList">
                        <li className="topListItem">
                          <Link className="link " to="/login">
                            Login
                          </Link>
                        </li>
                        <li className="topListItem">
                          <Link className="link" to="/register">
                            Register
                          </Link>
                        </li>
                      </ul>
                    )}

                  </div> */}

                  {/* <a
                    href="#"
                    className="px-3 py-2 text-sm font-medium rounded-md "
                  >
                    Reports
                  </a> */}
                </div>
              </div>
            </div>
            <div className="flex pr-4 -mr-2 md:hidden">
              <button
                onClick={() => setIsOpen(!isOpen)}
                type="button"
                className="inline-flex items-center justify-center p-2 text-white bg-gray-800 border-white rounded-md sm:pr-4 hover:text-white hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
                aria-controls="mobile-menu"
                aria-expanded="false"
              >
                <span className="sr-only">Open main menu</span>
                {!isOpen ? (
                  <svg
                    className="block w-6 h-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M4 6h16M4 12h16M4 18h16"
                    />
                  </svg>
                ) : (
                  <svg
                    className="block w-6 h-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                )}
              </button>
            </div>
          </div>
        </div>


        <Transition
          show={isOpen}
          enter="transition ease-out duration-100 transform"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="transition ease-in duration-75 transform"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          {(ref) => (
            <div className="w-full bg-white md:hidden" id="mobile-menu">
              <hr class="h-4px bg-gray-200 border-1 dark:bg-gray-700" />

              <div ref={ref} className="w-full px-2 pt-2 pb-3 space-y-1 bg-white sm:px-3">
                <Link
                  to={`/`}
                  className="block px-3 py-2 text-sm text-base font-medium rounded-md "
                >
                  Home
                </Link>

                <Link to={`/teachings`}
                  className="block px-3 py-2 text-sm text-base font-medium rounded-md "
                >
                  Teachings
                </Link>

                <Link
                  to='/events'
                  className="block px-3 py-2 text-sm text-base font-medium rounded-md "
                >
                  Events
                </Link>

                {/* <Link
                  to="/write"
                  className="block px-3 py-2 text-sm text-base font-medium rounded-md "
                >
                  Write
                </Link> */}

                <Link
                  to={`/contactus`}
                  className="block px-3 py-2 text-sm text-base font-medium rounded-md "
                >
                  Contact Us
                </Link>
                <Link
                  to="/donation"
                  className="block px-3 py-2 text-sm text-base font-medium rounded-md"
                >
                  Donate
                </Link>
                {/* <div className="topRightab">
                  {user ? (



                    <NavDropdown title={<div className='dropdownprofile'><img className="topImg" src={user.profilePic} alt="" /></div>}>
                      <NavDropdown.Item><Link to="/settings">Profile</Link></NavDropdown.Item>

                      <NavDropdown.Item onClick={handleLogout}>Log Out</NavDropdown.Item>

                    </NavDropdown>

                  ) : (
                    <ul className="mt-2 topList">
                      <li className="topListItem">
                        <Link className="link " to="/login">
                          Login
                        </Link>
                      </li>
                      <li className="topListItem">
                        <Link className="link" to="/register">
                          Register
                        </Link>
                      </li>
                    </ul>
                  )}

                </div> */}


              </div>

            </div>
          )}
        </Transition>
      </nav >


    </div >
  )
}

