import React from 'react'
import { useState } from 'react'
import './contact-us.css'
import { send } from 'emailjs-com';
import { errorNotification, successNotification } from '../../utils/notifications';





export default function ContactUs() {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const [error, setError] = useState(false);


    const [toSend, setToSend] = useState({
        from_name: '',
        to_name: '',
        message: '',
        reply_to: '',
    });



    const onSubmit = (e) => {
        e.preventDefault();
        console.log(e.target.value)

        send(
            'service_6h1fhxv',
            'template_ub9yt1fl',
            toSend,
            'RKrn3QJFjrXIWMs2u'
        )
            .then((response) => {
                console.log('SUCCESS!', response.status, response.text);
                successNotification("Successfully Sent");
            })
            .catch((err) => {
                console.log('FAILED...', err);
                errorNotification("Faild to Send")
            });
    };

    const handleChange = (e) => {
        console.log(e)
        setToSend({ ...toSend, [e.target.name]: e.target.value });
    };


    return (
        <div>
            <section class="text-gray-600 body-font relative">

                <div class="absolute inset-0 bg-gray-300 " >
                    <iframe width="100%" height="100%" frameborder="0" marginheight="0" marginwidth="0" title="map" scrolling="no" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3940.4606869150916!2d38.8696442!3d9.0216707!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x164b91622c1e2d3b%3A0x218cf8e4169e752!2sAyat%20Mekane%20Yesus%20Church!5e0!3m2!1sen!2snl!4v1682058327296!5m2!1sen!2snl"></iframe>
                </div>
                <form onSubmit={onSubmit} id='feedback-form'>

                    <div class="container px-5 py-24 mx-auto flex items-center justify-center">
                        <div class="lg:w-1/3 md:w-1/2 bg-white rounded-lg p-8 flex flex-col md:ml-auto w-full mt-10 md:mt-0 relative z-10 shadow-md">
                            <h2 class="text-gray-900 text-lg mb-1 font-medium title-font">Contact Us</h2>
                            <p class="leading-relaxed mb-3 text-gray-600">You can put your feedback messages sooner we will get reach out to you. </p>
                            <div class="relative mb-4">
                                <label for="from_name" class="leading-7 text-sm text-gray-600">Name</label>
                                <input type="text" id="from_name" name="from_name" class="w-full bg-white rounded border border-gray-300 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" value={toSend.from_name} onChange={handleChange} placeholder='Enter your name' required />
                            </div>
                            <div class="relative mb-4">
                                <label for="email" class="leading-7 text-sm text-gray-600">E-mail</label>
                                <input type="email" id="reply_to" name="reply_to" class="w-full bg-white rounded border border-gray-300 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" value={toSend.reply_to} onChange={handleChange} placeholder='Enter your email' required />
                            </div>

                            <div class="relative mb-2">
                                <label for="message" class="leading-7 text-sm text-gray-600">Message</label>
                                <textarea id="message" name="message" class="w-full bg-white rounded border border-gray-300 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 h-32 text-base outline-none text-gray-700 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out" value={toSend.message} onChange={handleChange} placeholder='Put your message and your phone number, As you want.' required></textarea>
                            </div>
                            <button class="text-white bg-blue-500 border-0 py-2 px-6 focus:outline-none hover:bg-blue-600 rounded text-lg" type='submit'>Submit</button>
                            <h1 class="text-base text-black mt-3 text-center">Also available on:</h1>


                            <div className='flex flex-row justify-center mx-3 my-3 space-x-3'>
                                {/* <button id="facebook" class="bg-transparent border-2  border-black bg-gradient-to-b text-2xl    min-w-wull hover:text-white bg- text-black w-12 h-12   hover:-translate-y-3 rounded-full duration-500" >
                                    <i class="fab fa-facebook-f"></i>
                                </button>

                                <button id="whatsapp" class="bg-transparent border-2  border-black bg-gradient-to-b text-2xl    min-w-wull hover:text-white bg- text-black w-12 h-12   hover:-translate-y-3 rounded-full duration-500">
                                    <i class="fab fa-whatsapp" ></i>
                                </button>

                                <button id="telegram" class="bg-transparent border-2  border-black bg-gradient-to-b text-2xl    min-w-wull hover:text-white bg- text-black w-12 h-12   hover:-translate-y-3 rounded-full duration-500">
                                    <i class="fab fa-telegram-plane"></i>
                                </button> */}
                                <div className='flex flex-row justify-center mx-3 my-3 space-x-3'>
                                    <a href="https://www.facebook.com/profile.php?id=100076109537337&mibextid=ZbWKwL" target="_blank" rel="noopener noreferrer">
                                        <button id="facebook" class="bg-transparent border-2  border-black bg-gradient-to-b text-2xl    min-w-wull hover:text-black bg- text-black w-12 h-12   hover:-translate-y-3 rounded-full duration-500">
                                            <i class="fab fa-facebook-f"></i>
                                        </button>
                                    </a>
                                    <a href="https://www.instagram.com/ayatmekaneyesuschurch/" target="_blank" rel="noopener noreferrer">
                                        <button id="instagram" class="bg-transparent border-2  border-black bg-gradient-to-b text-2xl    min-w-wull hover:text-black bg- text-black w-12 h-12   hover:-translate-y-3 rounded-full duration-500 ">
                                            <i class="fab fa-instagram"></i>
                                        </button>
                                    </a>
                                    {/* <button id="whatsapp" class="bg-transparent border-2  border-white bg-gradient-to-b text-2xl    min-w-wull hover:text-white bg- text-white w-12 h-12   hover:-translate-y-3 rounded-full duration-500">
              <i class="fab fa-whatsapp"></i>
            </button> */}
                                    <a href="https://www.youtube.com/@AyatMekaneYesusChurch" target="_blank" rel="noopener noreferrer">
                                        <button id="youtube" class="bg-transparent border-2  border-black bg-gradient-to-b text-2xl    min-w-wull hover:text-black bg- text-black w-12 h-12   hover:-translate-y-3 rounded-full duration-500" hre>
                                            <i class="fab fa-youtube" ></i>
                                        </button>
                                    </a>
                                    {/* <button id="tiktok" class="bg-transparent border-2  border-white bg-gradient-to-b text-2xl    min-w-wull hover:text-white bg- text-white w-12 h-12   hover:-translate-y-3 rounded-full duration-500">
              <i class="fab fa-tiktok"></i>
            </button> */}

                                    <a href="https://t.me/ayatmekaneyesus" target="_blank" rel="noopener noreferrer">
                                        <button id="telegram" class="bg-transparent border-2  border-black bg-gradient-to-b text-2xl    min-w-wull hover:text-black bg- text-black w-12 h-12   hover:-translate-y-3 rounded-full duration-500">
                                            <i class="fab fa-telegram-plane"></i>
                                        </button>
                                    </a>
                                </div>
                            </div>
                            {/* <h6 class="text-base text-black  text-center">sd</h6> */}
                        </div>

                    </div>
                </form>
            </section>


        </div>


    )
}
