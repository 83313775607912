import { Container } from '@material-ui/core';
import { useEffect, useState } from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { useLocation } from 'react-router-dom';
import Posts from '../../components/posts/posts';
import Video from '../../components/video/video';
import api from '../../config/api';
import background from '../../image/test2.jpg';



import './videos.css';

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};
const PF = "https://ewnetegna-testimony-api.vercel.app/images/";

export default function Videos() {
  const [posts, setPosts] = useState([]);
  const { search } = useLocation();


  useEffect(() => {
    const fetchPosts = async () => {
      const res = await api.get("/teachings" + search);

      setPosts(res.data);
    }
    fetchPosts();
  }, [search]);

  return (
    <div className="mt-10">
      <header id="up" class="bg-center bg-fixed bg-no-repeat bg-center bg-cover h-screen relative">
        <div class="h-screen bg-opacity-50 bg-black flex items-center justify-center" style={{
          backgroundImage: `linear-gradient(
                    rgba(4, 93, 184, 0.5), 
                    rgba(4, 93, 184, 0.8)
                ), url(${background})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}>
          <div class="mx-2 text-center">
            <div className='flex justify-center mt-10'>
              <span className='mr-2 text-4xl text-yellow-200'>“</span>
              <h6 class="text-gray-100 font-semibold text-base xs:text-base md:text-lg w-1/3 line-clamp-3 mb-2">
                So Christ himself gave the apostles, the prophets, the evangelists, the pastors and teachers, to equip his people for works of service, so that the body of Christ may be built up until we all reach unity in the faith and in the knowledge of the Son of God and become mature, attaining to the whole measure of the fullness of Christ. Then we will no longer be infants, tossed back and forth by the waves, and blown here and there by every wind of teaching and by the cunning and craftiness of people in their deceitful scheming. Instead, speaking the truth in love, we will grow to become in every respect the mature body of him who is the head, that is, Christ.
              </h6>
              <span className='flex items-end ml-2 text-4xl text-yellow-200'>”</span>
            </div>
            <h6 class="text-gray-100 font-medium text-lg xs:text-base md:text-md">
              <span class="text-white">Ephesians 4:11-15</span>
            </h6>
            {/* <button class="p-2 my-5 mx-2 bg-blue-500 hover:bg-blue-600 font-bold text-white rounded border-2 border-transparent hover:border-blue-600 shadow-md transition duration-500 md:text-xl">Hire US!</button> */}
            {/* <a href="#about"><button class="p-2 my-5 mx-2 bg-transparent border-2 bg-blue-500 bg-opacity-75 hover:bg-opacity-100 border-white rounded hover:border-blue-500 hover:bg-blue-500 font-bold text-white shadow-md transition duration-500 md:text-lg">Know More</button></a> */}
          </div>
        </div>
      </header>
      {/* <h1 class="sm:text-3xl text-2xl text-center font-medium title-font mb-2 text-gray-900 m-5">Teachings</h1> */}

      {/* <Container>
        <h4 className="my-3 font-bold text-secondary">Sunday Teachings</h4>
        <Carousel className='justify-evenly' responsive={responsive} centerMode={true} swipeable={true} draggable={true} infinite={true} keyBoardControl={true} renderDotsOutside={true}>
          <Video src="https://www.youtube.com/embed/w-KF26jebQI" url={PF + `ortho1.png`} />
          <Video src="https://www.youtube.com/embed/M6eKSuFYWF4" url={PF + `ortho2.png`} />
          <Video src="https://www.youtube.com/embed/VmN1p2HZBK8" url={PF + `ortho3.png`} />
          <Video src="https://www.youtube.com/embed/BBGfku6G5Fw" url={PF + `ortho4.png`} />
        </Carousel>
        <hr />
        <h4 className="my-3 font-bold text-secondary">Wednesday Teachings</h4>
        <Carousel className=' justify-evenly' responsive={responsive} centerMode={true} swipeable={true} draggable={true} infinite={true} keyBoardControl={true} renderDotsOutside={true}>
          <Video src="https://www.youtube.com/embed/O5I9l2ZHFvU" url={PF + `islam1.png`} />
          <Video src="https://www.youtube.com/embed/IIReNZuPM6M" url={PF + `islam2.png`} />
          <Video src="https://www.youtube.com/embed/GDn2SSpEL-Y" url={PF + `islam3.png`} />
          <Video src="https://www.youtube.com/embed/uj8pK7VfBto" url={PF + `islam4.png`} />
        </Carousel>
        <hr />
      </Container> */}
      <Posts posts={posts} />

    </div>
  )
}
