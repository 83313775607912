import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Post from '../post/post';
import { Link } from "react-router-dom";
import {
    Player,
    ControlBar,
    ReplayControl,
    ForwardControl,
    CurrentTimeDisplay,
    TimeDivider,
    PlaybackRateMenuButton,
    VolumeMenuButton
} from 'video-react';
import YouTube from 'react-youtube';
import React, { useEffect, useState } from 'react';
import { Checkbox, Divider, Input, Pagination, Popover, Select } from "antd";






import './posts.css';
import { Slider } from 'antd';

export default function Posts({ posts }) {
    const [page, setPage] = React.useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalposts, setTotalDiscussions] = useState(0);

    // useEffect(() => {
    //     console.log("Posts" + posts[1].descritpion)
    // });




    const opts = {
        height: '180',
        width: '240',
        playerVars: {
            autoplay: 0,
        },
    };

    function extractVideoIdFromUrl(url) {
        console.log(url);
        const searchParams = new URLSearchParams(new URL(url).search);
        return searchParams.get('v');
    }

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: true,
                    dots: true,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1,
                },
            },
        ],
    };


    return (
        <section class="text-gray-600 body-font justify-center flex items-center">
            <div class="container px-5 py-10 mx-auto flex flex-col justify-center">
                <div class="flex flex-wrap w-full mb-[20px]">
                    <div class=" w-full mb-3 lg:mb-0 flex justify-between">
                        <div>
                            <h1 class="sm:text-3xl text-2xl font-medium title-font mb-2 text-gray-900">Teachings</h1>
                            <div class="h-1 w-24 bg-blue-500 rounded"></div>
                        </div>
                        <Link to={`/teachings`}
                            className="block px-3 py-2 text-sm text-base font-medium rounded-md "
                        >
                            See more
                        </Link>

                    </div>
                    <p class="lg:w-2/2 w-3/4 leading-relaxed text-gray-500">Did you miss last week's sermon or that Bible study from a few months back? No worries - we've got you covered. All of our church's past events are documented here along with the important scripture verses and topics discussed. Whether you had to miss church or just want to review the material, you can find them all here. .</p>
                </div>

                <div className='flex flex-wrap -m-4  -m-4 max-h-[700px] overflow-x-auto'>

                    {posts.map((post) => (


                        <div class="xl:w-1/4 md:w-1/2 p-4 " key={post._id}>

                            <div class="bg-gray-100 p-6 rounded-lg ">
                                {/* <img class="h-40 rounded w-full object-cover object-center mb-6" src={post.photo} alt="content" /> */}

                                <YouTube className='flex flex-col items-center justify-center object-contain w-full mb-3 rounded ' opts={opts} videoId={extractVideoIdFromUrl(post.youtube_link)} />

                                <h2 class="text-lg text-gray-900 font-medium title-font mb-1 line-clamp-1">{post.title}</h2>
                                <p class="text-sm mb-3">{new Date(post.createdAt).toDateString()}</p>
                                <p class="text-base text-gray-700 mb-3 line-clamp-1">By: {post.preacher}</p>

                                {/* <p class="leading-relaxed text-base line-clamp-3">{post.descritpion}</p> */}
                                <p class="leading-relaxed text-base line-clamp-3">{post.descritpion.split('\n').map((line, index) => (
                                    <p key={index}>{line}</p>
                                ))}</p>
                                <div className='flex items-center justify-center'>
                                    <Link to={`/post/${post._id}`} className=""><a class="py-[4px] px-[10px] text-white bg-blue-600 hover:bg-blue-400 focus:ring-4 mt-3 focus:ring-blue-300 font-medium rounded-lg text-sm text-center inline-flex items-center  dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                                        Read more
                                        <svg class="-mr-1 ml-2 h-4 w-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                                    </a>
                                    </Link>
                                </div>

                            </div>


                        </div>


                    ))}

                </div>
                <div className='flex justify-center mx-10 '>

                    <Pagination
                        className='rounded-md bg-slate-200'
                        current={currentPage}
                        hideOnSinglePage={true}
                        total={totalposts}
                        onChange={(pageNumber) => setCurrentPage(pageNumber)}
                    />
                </div>

            </div>
            <div>

            </div>
        </section>
    )
}





