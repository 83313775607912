import React from 'react'
import './footer.css'
import footerlogo from '../../image/logo-cross-only.png';
import { Link } from 'react-router-dom';


export default function Footer() {
    return (
        <footer class="text-gray-600 body-font">
            <div class="container px-5  mx-auto flex md:items-center lg:items-center md:flex-row md:flex-nowrap flex-wrap flex-col mt-4">


                <div class="flex-grow flex flex-wrap md:pl-20  md:mt-0  md:text-left flex-0 ">

                    <div class="flex flex-col ml-0 justify-center items-center text-black w-full">

                        <section class="text-gray-600 body-font">
                            <div class="container px-5 py-2 mx-auto">
                                <div class="flex flex-wrap w-full mb-[20px]">
                                    <div class=" w-full mb-3 lg:mb-0 flex justify-between">
                                        <div>
                                            <h1 class="sm:text-3xl text-2xl font-medium title-font mb-2 text-gray-900">Regular Programs</h1>
                                            <div class="h-1 w-24 bg-blue-500 rounded"></div>
                                        </div>
                                    </div>
                                    <p class="lg:w-2/2 w-3/4 leading-relaxed text-gray-500">Check out our online calendar to see what's happening each week and weekend. We've got Sunday services, prayer meetings, youth group activities and many more events!.</p>
                                </div>
                                <div class="flex flex-wrap">
                                    <div class="xl:w-1/4 lg:w-1/2 md:w-full px-8 py-4 border-l-2 border-gray-200 border-opacity-60">
                                        <h2 class="text-lg sm:text-xl text-gray-900 font-medium title-font">Every Tuesday</h2>
                                        <h5 className='my-1 font-semibold'> 4:00 - 8:00 LT</h5>
                                        <p class="leading-relaxed text-base mb-4">Fasting and prayer</p>
                                    </div>
                                    <div class="xl:w-1/4 lg:w-1/2 md:w-full px-8 py-4 border-l-2 border-gray-200 border-opacity-60">
                                        <h2 class="text-lg sm:text-xl text-gray-900 font-medium title-font">Every Wednesday</h2>
                                        <h5 className='my-1 font-semibold'> 2:30 - 5:00 LT</h5>
                                        <p class="leading-relaxed text-base mb-4">Series Bible Scriptures Teaching</p>

                                    </div>
                                    <div class="xl:w-1/4 lg:w-1/2 md:w-full px-8 py-4 border-l-2 border-gray-200 border-opacity-60">
                                        <h2 class="text-lg sm:text-xl text-gray-900 font-medium title-font ">Every Saturday</h2>
                                        <h5 className='my-1 font-semibold'>9:00-11:00 LT(Foster youth program)</h5>
                                        <h5 className='my-1 font-semibold'>9:00-12:00 LT(Youth Program)</h5>
                                        <p class="leading-relaxed text-base mb-4">Foster youth & Youth Program</p>

                                    </div>
                                    <div class="xl:w-1/4 lg:w-1/2 md:w-full px-8 py-4 border-l-2 border-gray-200 border-opacity-60">
                                        <h2 class="text-lg sm:text-xl text-gray-900 font-medium title-font ">Every Sunday</h2>
                                        <h5 className='my-1 font-semibold'>2:30-5:00 LT(First shift )</h5>
                                        <h5 className='my-1 font-semibold'>5:00-7:00 LT(Second shift )</h5>
                                        <p class="leading-relaxed text-base mb-4">Sunday Service prayer and worship schedule</p>
                                    </div>

                                </div>
                            </div>
                        </section>

                    </div>

                </div>
                <div class="w-64 flex-shrink-0 md:mx-0 mx-auto text-center md:text-left items-center flex my-4">
                    <a class="flex title-font font-medium items-center md:justify-start justify-center text-gray-900">
                        <img src={footerlogo} width={110} />
                        <span class="ml-3 text-xl">Ayat Mekane Yesus Church</span>
                    </a>
                </div>
            </div >
            <div class="bg-gray-100">
                <div class="container mx-auto py-4 px-5 flex flex-wrap flex-col sm:flex-row justify-center">
                    <p class="text-black text-sm text-center sm:text-left">© 2023  —
                        <a href="https://twitter.com/knyttneve" rel="noopener noreferrer" class="text-black ml-1" target="_blank">Ayat Mekane Yesus Media Team</a>
                    </p>

                </div>
            </div>
        </footer >
    )
}
