import React from 'react'

import webimage1 from '../../assets/gallery/website-1.jpg';
import webimage2 from '../../assets/gallery/website-2.jpg';
import webimage3 from '../../assets/gallery/website-3.jpg';
import webimage4 from '../../assets/gallery/website-4.jpg';
import webimage5 from '../../assets/gallery/website-7.jpg';
import webimage6 from '../../assets/gallery/website-8.jpg';
import picture1 from '../../image/picture-1.jpg';
import picture2 from '../../image/picture-2.jpg';
import picture3 from '../../image/picture-3.jpg';
import picture4 from '../../image/picture-4.jpg';
import picture5 from '../../image/picture-5.jpg';
import picture6 from '../../image/picture-6.jpg';





function Gallery() {
    return (
        // <section class="text-gray-600 body-font mb-5">
        //     <div class="container px-5 py- mx-auto">
        //         <div class="flex flex-col text-center w-full mb-4">
        //             <h1 class="sm:text-3xl text-2xl font-medium title-font mb-3 text-gray-900">Ayat Mekane Yesus Church</h1>
        //             <p class="lg:w-2/3 mx-auto leading-relaxed text-base">Whatever cardigan tote bag tumblr hexagon brooklyn asymmetrical gentrify, subway tile poke farm-to-table. Franzen you probably haven't heard of them man bun deep jianbing selfies heirloom.</p>
        //         </div>
        //         <div class="flex flex-wrap ">
        //             <div class="lg:w-1/3 sm:w-1/2 p-4">
        //                 <div class="flex relative">
        //                     <img src={webimage1} className='absolute inset-0 object-cover object-center w-full h-full' />

        //                     <div class="px-8 py-10 relative z-10 w-full border-4 border-gray-200 bg-white opacity-0 hover:opacity-100">
        //                         <h2 class="tracking-widest text-sm title-font font-medium text-indigo-500 mb-1">THE SUBTITLE</h2>
        //                         <h1 class="title-font text-lg font-medium text-gray-900 mb-3">Shooting Stars</h1>
        //                         <p class="leading-relaxed">Photo booth fam kinfolk cold-pressed sriracha leggings jianbing microdosing tousled waistcoat.</p>
        //                     </div>
        //                 </div>
        //             </div>
        //             <div class="lg:w-1/3 sm:w-1/2 p-4">
        //                 <div class="flex relative">
        //                     <img src={webimage2} className='absolute inset-0 object-cover object-center w-full h-full' />
        //                     <div class="px-8 py-10 relative z-10 w-full border-4 border-gray-200 bg-white opacity-0 hover:opacity-100">
        //                         <h2 class="tracking-widest text-sm title-font font-medium text-indigo-500 mb-1">THE SUBTITLE</h2>
        //                         <h1 class="title-font text-lg font-medium text-gray-900 mb-3">The Catalyzer</h1>
        //                         <p class="leading-relaxed">Photo booth fam kinfolk cold-pressed sriracha leggings jianbing microdosing tousled waistcoat.</p>
        //                     </div>
        //                 </div>
        //             </div>
        //             <div class="lg:w-1/3 sm:w-1/2 p-4">
        //                 <div class="flex relative">
        //                     <img src={webimage3} className='absolute inset-0 object-cover object-center w-full h-full' />
        //                     <div class="px-8 py-10 relative z-10 w-full border-4 border-gray-200 bg-white opacity-0 hover:opacity-100">
        //                         <h2 class="tracking-widest text-sm title-font font-medium text-indigo-500 mb-1">THE SUBTITLE</h2>
        //                         <h1 class="title-font text-lg font-medium text-gray-900 mb-3">The 400 Blows</h1>
        //                         <p class="leading-relaxed">Photo booth fam kinfolk cold-pressed sriracha leggings jianbing microdosing tousled waistcoat.</p>
        //                     </div>
        //                 </div>
        //             </div>
        //             <div class="lg:w-1/3 sm:w-1/2 p-4">
        //                 <div class="flex relative">
        //                     <img src={webimage4} className='absolute inset-0 object-cover object-center w-full h-full' />
        //                     <div class="px-8 py-10 relative z-10 w-full border-4 border-gray-200 bg-white opacity-0 hover:opacity-100">
        //                         <h2 class="tracking-widest text-sm title-font font-medium text-indigo-500 mb-1">THE SUBTITLE</h2>
        //                         <h1 class="title-font text-lg font-medium text-gray-900 mb-3">Neptune</h1>
        //                         <p class="leading-relaxed">Photo booth fam kinfolk cold-pressed sriracha leggings jianbing microdosing tousled waistcoat.</p>
        //                     </div>
        //                 </div>
        //             </div>
        //             <div class="lg:w-1/3 sm:w-1/2 p-4">
        //                 <div class="flex relative">
        //                     <img src={webimage5} className='absolute inset-0 object-cover object-center w-full h-full' />
        //                     <div class="px-8 py-10 relative z-10 w-full border-4 border-gray-200 bg-white opacity-0 hover:opacity-100">
        //                         <h2 class="tracking-widest text-sm title-font font-medium text-indigo-500 mb-1">THE SUBTITLE</h2>
        //                         <h1 class="title-font text-lg font-medium text-gray-900 mb-3">Holden Caulfield</h1>
        //                         <p class="leading-relaxed">Photo booth fam kinfolk cold-pressed sriracha leggings jianbing microdosing tousled waistcoat.</p>
        //                     </div>
        //                 </div>
        //             </div>
        //             <div class="lg:w-1/3 sm:w-1/2 p-4">
        //                 <div class="flex relative">
        //                     <img src={webimage6} className='absolute inset-0 object-cover object-center w-full h-full' />
        //                     <div class="px-8 py-10 relative z-10 w-full border-4 border-gray-200 bg-white opacity-0 hover:opacity-100">
        //                         <h2 class="tracking-widest text-sm title-font font-medium text-indigo-500 mb-1">THE SUBTITLE</h2>
        //                         <h1 class="title-font text-lg font-medium text-gray-900 mb-3">Alper Kamu</h1>
        //                         <p class="leading-relaxed">Photo booth fam kinfolk cold-pressed sriracha leggings jianbing microdosing tousled waistcoat.</p>
        //                     </div>
        //                 </div>
        //             </div>
        //         </div>
        //     </div>
        // </section>

        <section class="text-gray-600 body-font">
            <div class="container px-5 py-24 mx-auto flex flex-wrap">
                <div class="flex w-full mb-20 flex-wrap">
                    <h1 class="sm:text-3xl text-2xl font-medium title-font text-gray-900 lg:w-1/3 lg:mb-0 mb-4">Thankful Message</h1>
                    <p class="lg:pl-6 lg:w-2/3 mx-auto leading-relaxed text-base">What an amazing year of ministry together! It's been so great to see how God brought our church family even closer through our shared faith. Our memory book captures some highlights from 2021 - baptisms, mission trips, community service projects. We're beyond grateful for how the Lord empowered us to come together and make a difference. All glory belongs to Him!</p>
                </div>
                <div class="flex flex-wrap md:-m-2 -m-1">
                    <div class="flex flex-wrap w-1/2">
                        <div class="md:p-2 p-1 w-1/2">
                            <img alt="gallery" class="w-full object-cover h-full object-center block" src={picture4} />
                        </div>
                        <div class="md:p-2 p-1 w-1/2">
                            <img alt="gallery" class="w-full object-cover h-full object-center block" src={picture2} />
                        </div>
                        <div class="md:p-2 p-1 w-full">
                            <img alt="gallery" class="w-full h-full object-cover object-center block" src={picture1} />
                        </div>
                    </div>
                    <div class="flex flex-wrap w-1/2">
                        <div class="md:p-2 p-1 w-full">
                            <img alt="gallery" class="w-full h-full object-cover object-center block" src={picture5} />
                        </div>
                        <div class="md:p-2 p-1 w-1/2">
                            <img alt="gallery" class="w-full object-cover h-full object-center block" src={picture3} />
                        </div>
                        <div class="md:p-2 p-1 w-1/2">
                            <img alt="gallery" class="w-full object-cover h-full object-center block" src={picture6} />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Gallery