import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import './singlePost.css';
import { Context } from "../../context/Context";
import api from '../../config/api';
import { AiOutlineArrowLeft } from "react-icons/ai";
import { useHistory } from 'react-router-dom';
import YouTube from 'react-youtube';
import { useMediaQuery } from 'react-responsive';





export default function SinglePost() {
    const location = useLocation();
    const path = location.pathname.split("/")[2];
    const [post, setPost] = useState({});
    const PF = "http://localhost:5000/images/";
    const { user } = useContext(Context);
    const [title, setTitle] = useState("");
    const [descritpion, setDesc] = useState("");
    const [updateMode, setUpdateMode] = useState(false);
    const history = useHistory();

    const isSmallScreen = useMediaQuery({ maxWidth: 767 });
    const playerWidth = isSmallScreen ? '100%' : '640';

    const opts = {
        height: '450',
        width: playerWidth,
        playerVars: {
            autoplay: 0,
        },
    };


    useEffect(() => {
        const getPost = async () => {
            const res = await api.get("/teachings/" + path);
            setPost(res.data);
        }
        getPost();
    }, [path]);

    const handleDelete = async () => {
        try {
            await axios.delete(`/teachings/${post._id}`, {
                data: { username: user.username },
            });
            window.location.replace("/");
        } catch (err) { }
    };

    const handleUpdate = async () => {
        try {
            await axios.put(`/teachings/${post._id}`, {
                username: user.username,
                title,
                descritpion,
            });
            setUpdateMode(false);
            window.location.reload();
        } catch (err) { }
    };

    function GoBack() {
        console.log("Working")
        history.goBack();
    }
    function extractVideoIdFromUrl(url) {
        const searchParams = new URLSearchParams(new URL(url).search);
        return searchParams.get('v');
    }


    return (
        <div className="mx-1 singlePost" key={post._id}>
            <div className="singlePostWrapper">
                <div className='flex flex-row justify-center mt-20 align-middle'>
                    <div className='h-fit' >
                        <a className='pr-3 hover:text-blue-500' onClick={GoBack}>Back</a>
                    </div>

                    {post.youtube_link && (
                        // <img src={post.youtube_link || "https://res.cloudinary.com/ramjet-it-solution/image/upload/v1657281849/cld-sample-4.jpg"} alt="" className="singlePostImg" />
                        <div className='relative"'>
                            <YouTube className="top-0 left-0 w-full h-full pr-2" opts={opts} videoId={extractVideoIdFromUrl(post.youtube_link)} />
                        </div>


                    )}                </div>

                {updateMode ? (
                    <input
                        type="text"
                        value={title}
                        className="singlePostTitleInput"
                        autoFocus
                        placeholder='Your Title'
                        onChange={(e) => setTitle(e.target.value)}
                    />
                ) : (
                    <h1 className="singlePostTitle">
                        {post.title}
                        {post.username === user?.username && (
                            <div className="singlePostEdit">
                                <i
                                    className="singlePostIcon far fa-edit"
                                    onClick={() => setUpdateMode(true)}
                                ></i>
                                <i
                                    className="singlePostIcon far fa-trash-alt"
                                    onClick={handleDelete}
                                ></i>
                            </div>
                        )}
                    </h1>
                )}
                <div className="singlePostInfo">
                    <span className="singlePostAuthor">
                        Preacher:
                        <Link to={`/?preacher=${post.preacher}`} className="link">
                            <b> {post.preacher}</b>
                        </Link>
                    </span>
                    <span className="singlePostDate">
                        {new Date(post.createdAt).toDateString()}
                    </span>
                </div>
                <div className="singlePostInfom">
                    Category :
                    <span className="singlePostAuthor">
                        <b> {post.categories} </b>
                    </span>
                </div>



                {updateMode ? (
                    <textarea
                        className="singlePostDescInput"
                        placeholder='Your description'
                        value={descritpion}
                        onChange={(e) => setDesc(e.target.value)}
                    />
                ) : (

                    <p class="mb-3 text-gray-500 dark:text-gray-400">{post.descritpion}</p>
                    // <p class="leading-relaxed text-base ">{post.descritpion.split('\n').map((line, index) => (
                    //     <p key={index}>{line}</p>
                    // ))}</p>



                )}
                {updateMode && (
                    <button className="singlePostButton" onClick={handleUpdate}>
                        Update
                    </button>
                )}
                <div className="singlePostInfo">
                    <span className="">
                        Hash Tags :
                        <b > {post.hashtags} </b>
                    </span>
                </div>
            </div>
        </div >
    );
}