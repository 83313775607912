import React, { useState } from 'react';
import background from '../../image/test2.jpg';
import Telebir from '../../image/Telebir-logo.png';
import CBEBank from '../../image/CBE-logo.png';
import AwashBank from '../../image/Awash-logo.png';
import BerhanBank from '../../image/Berhan-Logo.png';
import { Button, notification, Space } from 'antd';
import { FaRegCopy } from "react-icons/fa";

function Donation() {
    const [CBEAccount, setText] = useState('1000323246347');
    const [telebir, setTelebir] = useState('0911402076');
    const [AwashAccount, setAwashAccount] = useState('01352737999300');
    const [BerhanAccount, setBerhanAccount] = useState('1500270109842');
    const [Reason, setReason] = useState('ለቤተክርስቲያን ግምባታ');

    const [api, contextHolder] = notification.useNotification();

    const copyCBEText = () => {
        navigator.clipboard.writeText(CBEAccount).then(() => {
            api.success({
                message: 'Success',
                description: `Copied to clipboard: ${CBEAccount}`,
            });
        }).catch((error) => {
            api.error({
                message: 'Error',
                description: `Failed to copy text: ${error}`,
            });
        });
    };
    const copyTextFlow = () => {
        navigator.clipboard.writeText(Reason).then(() => {
            api.success({
                message: 'Success',
                description: `Copied to clipboard: ${Reason}`,
            });
        }).catch((error) => {
            api.error({
                message: 'Error',
                description: `Failed to copy text: ${error}`,
            });
        });
    };
    const copyTelebirr = () => {
        navigator.clipboard.writeText(telebir).then(() => {
            api.success({
                message: 'Success',
                description: `Copied to clipboard: ${telebir}`,
            });
        }).catch((error) => {
            api.error({
                message: 'Error',
                description: `Failed to copy text: ${error}`,
            });
        });
    };
    const copyAwashbirr = () => {
        navigator.clipboard.writeText(AwashAccount).then(() => {
            api.success({
                message: 'Success',
                description: `Copied to clipboard: ${AwashAccount}`,
            });
        }).catch((error) => {
            api.error({
                message: 'Error',
                description: `Failed to copy text: ${error}`,
            });
        });
    };
    const copyBerhanBank = () => {
        navigator.clipboard.writeText(BerhanAccount).then(() => {
            api.success({
                message: 'Success',
                description: `Copied to clipboard: ${BerhanAccount}`,
            });
        }).catch((error) => {
            api.error({
                message: 'Error',
                description: `Failed to copy text: ${error}`,
            });
        });
    };

    return (
        <div className='mt-[100px]'>
            {contextHolder}

            <header id="up" className="relative bg-fixed bg-center bg-no-repeat bg-cover">
                <div className="flex items-center justify-center bg-opacity-10" style={{
                    backgroundImage: `linear-gradient(
                    rgba(4, 93, 184, 0.6), 
                    rgba(4, 93, 184, 0.6)
                ), url(${background})`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                }}>
                    <div className="mx-2 text-center">
                        <section className="text-white body-font">
                            <div className="container px-5 mx-auto py-18">
                                <div className="flex flex-col w-full mb-20 text-center">
                                    <h1 className="py-4 text-2xl font-medium leading-tight text-gray-200 xs:text-3xl md:text-4xl">
                                        <span className='text-blue-100'>Building Together for God's Purpose</span>
                                    </h1>
                                    <p className="mx-auto text-base leading-relaxed lg:w-2/3">As we build our church, we lay each brick with faith, creating a holy temple for the Lord where His Spirit dwells among us.</p>
                                    <div className='flex-col justify-center block w-100'>
                                        <p className="mx-auto text-base leading-relaxed lg:w-2/3">Please put reason as : ለቤተክርስቲያን ግምባታ</p>
                                        <span className="inline-flex" onClick={copyTextFlow}>
                                            <FaRegCopy size={25} />
                                            <p className="ml-2">Copy</p>
                                        </span>
                                    </div>

                                </div>
                                <div className="flex flex-wrap justify-center m-4">
                                    {/* <div className="p-4 lg:w-1/4 md:w-1/2">
                                        <div className="flex flex-col items-center h-full text-center">
                                            <img alt="Telebirr" className="flex-shrink-0 object-cover object-center w-40 w-full h-40 mb-4 rounded-lg" src={Telebir} />
                                            <div className="w-full">
                                                <h2 className="text-lg font-medium text-white title-font">0911402076</h2>
                                                <p className="mb-0">Telebirr</p>
                                                <p className="mb-4">Asmeret Fescha</p>
                                                <span className="inline-flex" onClick={copyTelebirr}>
                                                    <FaRegCopy size={25} />
                                                    <p className="ml-2">Copy</p>
                                                </span>
                                            </div>
                                        </div>
                                    </div> */}
                                    <div className="p-4 lg:w-1/4 md:w-1/2">
                                        <div className="flex flex-col items-center h-full text-center">
                                            <img alt="team" className="flex-shrink-0 object-cover object-center w-40 w-full h-40 mb-4 rounded-lg" src={AwashBank} />
                                            <div className="w-full">
                                                <h2 className="text-lg font-medium text-white title-font">01352737999300</h2>
                                                <p className="mb-0">Awash Bank</p>
                                                <p className="mb-4">EECMY CES AYAT CONGREGATION</p>
                                                <span className="inline-flex" onClick={copyAwashbirr}>
                                                    <FaRegCopy size={25} />
                                                    <p className="ml-2">Copy</p>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="p-4 lg:w-1/4 md:w-1/2">
                                        <div className="flex flex-col items-center h-full text-center">
                                            <img alt="team" className="flex-shrink-0 object-cover object-center w-40 w-full h-40 mb-4 rounded-lg" src={CBEBank} />
                                            <div className="w-full">
                                                <h2 className="text-lg font-medium text-white title-font">1000323246347</h2>
                                                <p className="mb-0">CBE Account</p>
                                                <p className="mb-4">EECMY CES AYAT CONGREGATION</p>
                                                <span className="inline-flex" onClick={copyCBEText}>
                                                    <FaRegCopy size={25} />
                                                    <p className="ml-2">Copy</p>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="p-4 lg:w-1/4 md:w-1/2">
                                        <div className="flex flex-col items-center h-full text-center">
                                            <img alt="team" className="flex-shrink-0 object-cover object-center w-40 w-full h-40 mb-4 rounded-lg" src={BerhanBank} />
                                            <div className="w-full">
                                                <h2 className="text-lg font-medium text-white title-font">1500270109842</h2>
                                                <p className="mb-0">Berhan Bank</p>
                                                <p className="mb-4">EECMY CES AYAT CONGREGATION</p>
                                                <span className="inline-flex" onClick={copyBerhanBank} >
                                                    <FaRegCopy size={25} />
                                                    <p className="ml-2">Copy</p>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <div className='flex justify-center mt-2'>
                            <h6 className="w-1/3 mb-2 text-base font-semibold text-gray-100 xs:text-base md:text-base line-clamp-3">
                                Let us Keep intouch!
                            </h6>
                        </div>
                        <div className='flex flex-row justify-center mx-3 my-3 space-x-3'>
                            <a href="https://www.facebook.com/profile.php?id=100076109537337&mibextid=ZbWKwL" target="_blank" rel="noopener noreferrer">
                                <button id="facebook" class="bg-transparent border-2  border-white bg-gradient-to-b text-2xl    min-w-wull hover:text-white bg- text-white w-12 h-12   hover:-translate-y-3 rounded-full duration-500">
                                    <i class="fab fa-facebook-f"></i>
                                </button>
                            </a>
                            <a href="https://www.instagram.com/ayatmekaneyesuschurch/" target="_blank" rel="noopener noreferrer">
                                <button id="instagram" class="bg-transparent border-2  border-white bg-gradient-to-b text-2xl    min-w-wull hover:text-white bg- text-white w-12 h-12   hover:-translate-y-3 rounded-full duration-500 ">
                                    <i class="fab fa-instagram"></i>
                                </button>
                            </a>
                            {/* <button id="whatsapp" class="bg-transparent border-2  border-white bg-gradient-to-b text-2xl    min-w-wull hover:text-white bg- text-white w-12 h-12   hover:-translate-y-3 rounded-full duration-500">
              <i class="fab fa-whatsapp"></i>
            </button> */}
                            <a href="https://www.youtube.com/@AyatMekaneYesusChurch" target="_blank" rel="noopener noreferrer">
                                <button id="youtube" class="bg-transparent border-2  border-white bg-gradient-to-b text-2xl    min-w-wull hover:text-white bg- text-white w-12 h-12   hover:-translate-y-3 rounded-full duration-500" hre>
                                    <i class="fab fa-youtube" ></i>
                                </button>
                            </a>
                            {/* <button id="tiktok" class="bg-transparent border-2  border-white bg-gradient-to-b text-2xl    min-w-wull hover:text-white bg- text-white w-12 h-12   hover:-translate-y-3 rounded-full duration-500">
              <i class="fab fa-tiktok"></i>
            </button> */}

                            <a href="https://t.me/ayatmekaneyesus" target="_blank" rel="noopener noreferrer">
                                <button id="telegram" class="bg-transparent border-2  border-white bg-gradient-to-b text-2xl    min-w-wull hover:text-white bg- text-white w-12 h-12   hover:-translate-y-3 rounded-full duration-500">
                                    <i class="fab fa-telegram-plane"></i>
                                </button>
                            </a>
                        </div>
                    </div>
                </div>
            </header>
        </div>
    );
}

export default Donation;
