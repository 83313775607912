import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import './header.css';
import background from '../../image/test2.jpg';
import { FormattedMessage } from 'react-intl';
import { useState } from 'react';
import messages from '../../assets/messages';




export default function Header() {
  const [locale, setLocale] = useState('am');


  function reducer(state, action) {
    switch (action.type) {
      case 'SET_LOCALE':
        return {
          ...state,
          locale: action.payload.locale,
          messages: messages[action.payload.locale],
        };
      default:
        return state;
    }
  }

  const handleLocaleChange = (newLocale) => {
    setLocale(newLocale);
  };

  return (
    <header id="up" class="bg-center bg-fixed bg-no-repeat bg-center bg-cover h-screen relative">
      <div class="h-screen bg-opacity-50 bg-black flex items-center justify-center" style={{
        backgroundImage: `linear-gradient(
                    rgba(4, 93, 184, 0.5), 
                    rgba(4, 93, 184, 0.8)
                ), url(${background})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}>
        <div class="mx-2 text-center">
          <h2 class="text-gray-200 font-extrabold text-2xl xs:text-4xl md:text-4xl leading-tight">
            <span className='text-blue-300'>Growing</span> <span class="text-white">to</span> the <span class="text-blue-300 border-slate-400 border-solid">Fullness</span> of Christ
          </h2>
          <h1 class="text-gray-100 font-extrabold text-2xl xs:text-2xl md:text-4xl">
            <span class="text-white">Ayat Mekane Yesus Church</span>
          </h1>
          <div className='flex justify-center mt-10'>
            <span className='mr-2 text-4xl text-yellow-200'>“</span>
            <h6 class="text-gray-100 font-semibold text-base xs:text-base md:text-base w-1/3 line-clamp-3 mb-2">
              So Christ himself gave the apostles, the prophets, the evangelists, the pastors and teachers, to equip his people for works of service, so that the body of Christ may be built up until we all reach unity in the faith and in the knowledge of the Son of God and become mature, attaining to the whole measure of the fullness of Christ. Then we will no longer be infants, tossed back and forth by the waves, and blown here and there by every wind of teaching and by the cunning and craftiness of people in their deceitful scheming. Instead, speaking the truth in love, we will grow to become in every respect the mature body of him who is the head, that is, Christ.
            </h6>
            <span className='flex items-end ml-2 text-4xl text-yellow-200'>”</span>
          </div>
          <h6 class="text-gray-100 font-medium text-base xs:text-base md:text-sm">
            <span class="text-white">Ephesians 4:11-15</span>
          </h6>
          <div className='flex flex-row justify-center mx-3 my-3 space-x-3'>
            <a href="https://www.facebook.com/profile.php?id=100076109537337&mibextid=ZbWKwL" target="_blank" rel="noopener noreferrer">
              <button id="facebook" class="bg-transparent border-2  border-white bg-gradient-to-b text-2xl    min-w-wull hover:text-white bg- text-white w-12 h-12   hover:-translate-y-3 rounded-full duration-500">
                <i class="fab fa-facebook-f"></i>
              </button>
            </a>
            <a href="https://www.instagram.com/ayatmekaneyesuschurch/" target="_blank" rel="noopener noreferrer">
              <button id="instagram" class="bg-transparent border-2  border-white bg-gradient-to-b text-2xl    min-w-wull hover:text-white bg- text-white w-12 h-12   hover:-translate-y-3 rounded-full duration-500 ">
                <i class="fab fa-instagram"></i>
              </button>
            </a>
            {/* <button id="whatsapp" class="bg-transparent border-2  border-white bg-gradient-to-b text-2xl    min-w-wull hover:text-white bg- text-white w-12 h-12   hover:-translate-y-3 rounded-full duration-500">
              <i class="fab fa-whatsapp"></i>
            </button> */}
            <a href="https://www.youtube.com/@AyatMekaneYesusChurch" target="_blank" rel="noopener noreferrer">
              <button id="youtube" class="bg-transparent border-2  border-white bg-gradient-to-b text-2xl    min-w-wull hover:text-white bg- text-white w-12 h-12   hover:-translate-y-3 rounded-full duration-500" hre>
                <i class="fab fa-youtube" ></i>
              </button>
            </a>
            {/* <button id="tiktok" class="bg-transparent border-2  border-white bg-gradient-to-b text-2xl    min-w-wull hover:text-white bg- text-white w-12 h-12   hover:-translate-y-3 rounded-full duration-500">
              <i class="fab fa-tiktok"></i>
            </button> */}

            <a href="https://t.me/ayatmekaneyesus" target="_blank" rel="noopener noreferrer">
              <button id="telegram" class="bg-transparent border-2  border-white bg-gradient-to-b text-2xl    min-w-wull hover:text-white bg- text-white w-12 h-12   hover:-translate-y-3 rounded-full duration-500">
                <i class="fab fa-telegram-plane"></i>
              </button>
            </a>
          </div>
          {/* <button class="p-2 my-5 mx-2 bg-blue-500 hover:bg-blue-600 font-bold text-white rounded border-2 border-transparent hover:border-blue-600 shadow-md transition duration-500 md:text-xl">Hire US!</button> */}
          {/* <a href="#about"><button class="p-2  mx-2 bg-transparent border-2 bg-blue-500 bg-opacity-75 hover:bg-opacity-100 border-white rounded hover:border-blue-500 hover:bg-blue-500 font-bold text-white shadow-md transition duration-500 md:text-sm">Know More</button></a> */}
        </div>
      </div>
    </header>
  );
}
