import 'bootstrap/dist/css/bootstrap.min.css';
import TopBar from "./components/topbar/topBar";
import Home from "./pages/home/home";
import Login from "./pages/login/login";
import Single from "./pages/single/single";
import Videos from "./pages/videos/videos";
// import Write from "./pages/write/write";
import { useContext } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import './App.css';
import Footer from "./components/footer/footer";
import { Context } from "./context/Context";
import ContactUs from './pages/contact-us/contact-us';
import Register from "./pages/register/register";
import Setting from "./pages/settings/setting";
import Write from "./pages/write/write";
import "typeface-roboto";
import { useState } from 'react';
import { FormattedMessage, IntlProvider } from 'react-intl';
import React, { useReducer } from 'react';

import enMessages from './assets/language/en.json';
import amMessages from './assets/language/am.json';
import Events from './components/events/events';
import Donation from './components/donation/donation';

const messages = {
  en: enMessages,
  am: amMessages,
};

const initialState = {
  locale: 'en',
  messages: messages['en'],
};


function reducer(state, action) {
  switch (action.type) {
    case 'SET_LOCALE':
      return {
        ...state,
        locale: action.payload.locale,
        messages: messages[action.payload.locale],
      };
    default:
      return state;
  }
}



function App() {
  const [state, dispatch] = useReducer(reducer, initialState);


  const handleLocaleChange = (locale) => {
    dispatch({ type: 'SET_LOCALE', payload: { locale } });
  };


  const { user } = useContext(Context);

  return (
    <IntlProvider locale={state.locale} messages={state.messages}>
      <Router>
        <TopBar />
        <div className=''>
          <Switch>
            <Route exact path="/">
              <Home />
            </Route>
            <Route path="/teachings">
              <Videos />
            </Route>
            <Route path="/events">
              <Events />
            </Route>
            <Route path="/register">
              {user ? <Home /> : <Register />}
            </Route>
            <Route path="/login">
              {user ? <Home /> : <Login />}
            </Route>
            <Route path="/write">
              {user ? <Write /> : <Register />}
            </Route>
            <Route path="/settings">
              {user ? <Setting /> : <Register />}
            </Route>
            <Route path="/post/:postId">
              <Single />
            </Route>
            <Route path="/contactus">
              <ContactUs />
            </Route>
            <Route path="/donation">
              <Donation />
            </Route>
          </Switch>
        </div>
        <Footer />
      </Router>
    </IntlProvider>
  );
}

export default App;
