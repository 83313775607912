
import axios from "axios";
import { useEffect, useState } from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { useLocation } from 'react-router-dom';
import Events from "../../components/events/events";
import Footer from '../../components/footer/footer';
import Header from '../../components/header/header';
import Posts from '../../components/posts/posts';
import SideBar from '../../components/sidebar/sideBar';
import api from "../../config/api";
import getrequest from "../../config/api";
import Gallery from "../gallery/gallery";
import Program from "../program/program";
import './home.css';



export default function Home() {
    const [posts, setPosts] = useState([]);
    const { search } = useLocation();


    useEffect(() => {
        const fetchPosts = async () => {
            const res = await api.get("/teachings" + search);

            setPosts(res.data);
        }
        fetchPosts();
    }, [search]);

    return (
        <>
            <Header />
            <div>
                <div className='flex justify-center home'>

                    <div className="">
                        <Posts posts={posts} />
                    </div>
                    {/* <div className="w-1/4">
                        <SideBar />
                    </div> */}
                </div>
                <Events />
                <Gallery />

                <section class="text-gray-600 body-font relative my-5">
                    <div class="absolute inset-0 bg-gray-300">
                        <iframe width="100%" height="100%" frameborder="0" marginheight="0" marginwidth="0" title="map" scrolling="no" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3940.4606869150916!2d38.8696442!3d9.0216707!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x164b91622c1e2d3b%3A0x218cf8e4169e752!2sAyat%20Mekane%20Yesus%20Church!5e0!3m2!1sen!2snl!4v1682058327296!5m2!1sen!2snl"></iframe>
                    </div>
                    <div class="container py-32  flex flex-col justify-center ">
                        <div class="shadow-lg xl:p-6 p-4 sm:w-auto w-full bg-white sm:absolute relative z-20 sm:mt-0 mt-4 left-0 xl:ml-56 sm:ml-12 xl:-mt-40 sm:-mt-12 items-end">
                            <p class="text-3xl font-semibold text-gray-800">Zoom & See</p>
                            <p class="text-base leading-4 xl:mt-4 mt-2 text-gray-600">Zoom and Take a look to the map.</p>
                        </div>
                    </div>
                </section>



            </div>
        </>
    )
}
